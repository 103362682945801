var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.productGrid = {
    attach: function (context) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);

      // For any products set to OOS that are also in the 'ticker', hide them
      // Per CX-219, the brand says these are 'limited life' and need to not be displayed
      $(document).on('ticker_product_oos', '.js-product', function (event, passedProdId) {
        var $prodSlctr = $("[data-product-id='" + passedProdId + "']", context);
        var updatePageSliders = false;

        if ($prodSlctr && $prodSlctr.length > 0) {
          $prodSlctr.each(function () {
            var $product = $(this);
            var prodObj = prodcat.data.getProduct(passedProdId);
            var notSOSkus = [];
            var numberOfOccurrenceList = jQuery.grep(prodObj.skus, function (sku) {
              // Checking Sold Out and added in ticker.
              var isSoInTicker = sku.INVENTORY_STATUS === 7 && sku.highVelocityQty === 0;

              if (!isSoInTicker) {
                notSOSkus.push(sku.SKU_ID);
              }

              return isSoInTicker;
            }).length;
            var allSkuSO = numberOfOccurrenceList === prodObj.skus.length;

            $product.data('available-skus', notSOSkus.join(','));
            if (allSkuSO) {
              var isSpp = $product.hasClass('js-product-full');

              if (isSpp) {
                var localePrefix = Drupal.settings.pathPrefix || '';

                window.location.href = '/' + localePrefix;

                return false;
              }
              var $slickItem = $product.parent('.slick-slide');

              $product.remove();
              if ($slickItem.length > 0) {
                $slickItem.closest('.slick-slider.slick-initialized').data('updated', 1).attr('data-updated', 1);
                $slickItem.remove();
                updatePageSliders = true;
              }
            }
          });
          if (updatePageSliders) {
            // need to update sliders from the updated items, we filter based on the custom attr we add if slider
            // requires update
            $('.slick-slider')
              .filter('[data-updated="1"]')
              .each(function () {
                var $slider = $(this);

                $slider.data('updated', 0).attr('data-updated', 0);
                $slider.find('.slick-cloned').remove();
                $slider[0].slick.$prevArrow.remove();
                $slider[0].slick.$nextArrow.remove();
                $slider.slick('reinit');
              });
          }
          $grids.trigger('grid.reflow');
        }
      });

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $carousels.each(function () {
        var $carousel = $(this);
        var $arrowsDiv = $carousel.parent().find('.carousel-controls');
        var $dotsDiv = $carousel.parent().find('.carousel-dots');
        var slidesToShow = $carousel.closest('.js-product-grid').attr('data-grid-items-per-row');

        if (!!slidesToShow) {
          slidesToShow = JSON.parse(slidesToShow);
        } else {
          slidesToShow = {
            small: 1,
            medium: 1,
            large: 3
          };
        }
        var settings = {
          appendArrows: $arrowsDiv,
          infinite: true,
          slidesToShow: slidesToShow.large,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: $dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: slidesToShow.medium,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                appendDots: $dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: slidesToShow.small,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $carousel.data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $carousel.slick(settings);

        // On before slide change
        $(this).on('beforeChange', function () {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      // Filter event:
      $grids.on('productGrid.filter', function (event, prodsToShow, sort) {
        var $items = $('.js-product-grid-item', this);
        var $filteredItems = $items.filter(function () {
          return _.includes(prodsToShow, $(this).data('product-id'));
        });
        var $grid = $(this);
        var $container = $items.first().parent();

        // First, undo any previous sorting we may have done:
        _resetFilterSort($grid);

        // (Optionally) Sort:
        if (sort) {
          // Put a placeholder before the items we're going to sort so we can
          // un-sort them later (_resetFilterSort).
          $filteredItems.before(sortPlaceholder);
          // Reverse the array because we're prepending. Appending also works,
          // but this way we can target :first-child in css to get the primary
          // result in regimens.
          _.each(_.clone(prodsToShow).reverse(), function (id) {
            var $item = $filteredItems.filter('[data-product-id="' + id + '"]');

            $item.prependTo($container);
          });
        }

        // Filter:
        $items.addClass('hidden');
        $filteredItems.removeClass('hidden');

        $(this).trigger('grid.reflow');
      });

      // Reset filter event:
      $grids.on('productGrid.showAll', function () {
        _resetFilterSort($(this));
        $('.js-product-grid-item', this).removeClass('hidden');

        $(this).trigger('grid.reflow');
      });
    }
  };

  function _resetFilterSort($grid) {
    var $items = $('.js-product-grid-item', $grid);

    $('.js-product-grid-sort-placeholder', $grid).each(function () {
      var id = $(this).data('placeholder-product-id');
      var $item = $items.filter('[data-product-id="' + id + '"]');

      $(this).after($item).remove();
    });
  }
})(jQuery);
